import { Components } from 'api/fx/generated/client'
import { useIsLoginPage } from 'hooks/useIsLoginPage'
import { useSelector } from 'hooks/useSelector'
import { CLIENT_STATUS, completed, getStatus } from 'modules/helpers/clientStatuses/clientStatuses'
import { useIsUserLogged } from 'modules/keycloak'
import { useCallback, useEffect, useState } from 'react'
import { accountGet } from 'store/asyncThunks/accountGet'
import { profilesGet } from 'store/asyncThunks/profilesGet'
import { dispatch } from 'store/store'

type ProfileResponse = Components.Schemas.ProfileFrontofficeResponse
type AccountFrontofficeResponse = Components.Schemas.AccountFrontofficeResponse

export interface IClientReturned {
	refreshData: () => void
	isPending: boolean
	isTradingBlocked: boolean
	account?: AccountFrontofficeResponse
	profile?: ProfileResponse
	name: string
	isKycActive: boolean
	isKycCompleted: boolean
}
export const useUserData = (): IClientReturned => {
	const [isPending, setIsPending] = useState<boolean>(true)
	const { account, ids, profileId } = useSelector((state) => state.account)
	const profile = ids[profileId]
	const isLoginPage = useIsLoginPage()
	// FIXME - isUserLogged not necessary here, but after remove it ReferenceError appears
	useIsUserLogged()
	const kycProfile = account?.kycProfile

	const getData = useCallback(() => {
		dispatch(accountGet())
			.unwrap()
			.then(() => {
				const promises: Promise<any>[] = [dispatch(profilesGet())]

				Promise.all(promises).finally(() => setIsPending(false))
			})
			.finally(() => setIsPending(false))
	}, [])

	useEffect(() => {
		!isLoginPage && getData()
	}, [getData, isLoginPage])

	return {
		refreshData: getData,
		isPending,
		account,
		profile,
		isTradingBlocked: !!account?.blocked,
		name: kycProfile?.name || '',
		isKycActive: !!kycProfile?.isActive,
		isKycCompleted: completed.includes(getStatus(kycProfile?.status as CLIENT_STATUS)),
	}
}

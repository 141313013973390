import { ConfigProvider } from 'antd'
import en from 'antd/locale/en_GB'
import { getEnv } from 'config/env'
import { App } from 'App'
import { validateMessages } from 'modules/form/validation'
import { themeFx } from 'modules/styles/antd/theme'
import { GlobalStyles } from 'modules/styles/GlobalStyles'
import { isStickyParent } from 'modules/ui/Sticky'
import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor, store } from 'store/store'
import { ThemeProvider } from 'styled-components'
import { initFxApi } from 'api/fx/api'
import { startGtm } from 'scripts/gtm'

const initFxUi = () => {
	return getEnv()
		.then(() => Promise.all([startGtm(), initFxApi()]))
		.then(() => {
			ReactDOM.render(
				<StrictMode>
					<Provider store={store}>
						<PersistGate persistor={persistor} loading={null}>
							<ThemeProvider theme={{}}>
								<ConfigProvider
									form={{ validateMessages }}
									locale={en}
									getPopupContainer={(trigger) =>
										isStickyParent(trigger) && trigger?.parentElement ? trigger.parentElement : document.body
									}
									theme={themeFx}
								>
									<BrowserRouter>
										<GlobalStyles />
										<App />
									</BrowserRouter>
								</ConfigProvider>
							</ThemeProvider>
						</PersistGate>
					</Provider>
				</StrictMode>,
				document.getElementById('root'),
			)
		})
}

window.addEventListener('DOMContentLoaded', () => {
	initFxUi() // no need to return in the event
})

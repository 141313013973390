import { PrivateRouteLayout } from 'components/layout/PrivateRouteLayout'
import { Routes } from 'components/pages/Routes'
import { ENV } from 'config/env'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import countries from 'i18n-iso-countries'
import countriesLocale from 'i18n-iso-countries/langs/en.json'
import { useIsUserLogged, useKeycloakRefreshToken } from 'modules/keycloak'
import { CoverPage, CoverPageNevApp } from 'modules/ui/CoverPage'
import { FC } from 'react'
import { logoutAndClearState } from 'store/asyncThunks/logoutAndClearState'
import { dispatch } from 'store/store'
import { internetConnectionListener } from 'utils/internetConnectionListener'
import { updateLandingLoginCookie } from 'utils/landingLoginCookie'

internetConnectionListener()
countries.registerLocale(countriesLocale)
dayjs.extend(isBetween)
dayjs.locale('en_GB')

const App: FC = () => {
	useKeycloakRefreshToken({
		keycloakDomain: ENV.KEYCLOAK_DOMAIN,
		onRefreshSuccess: updateLandingLoginCookie,
		onRefreshError: () => dispatch(logoutAndClearState()),
	})
	const { isUserLogged } = useIsUserLogged()

	return (
		<PrivateRouteLayout>
			{/*<CoverPage visible={isUserLogged ? 'time' : !isUserLogged} />*/}
			{/*<Routes />*/}
			<CoverPageNevApp />
		</PrivateRouteLayout>
	)
}

export { App }

import { RocketOutlined } from '@ant-design/icons'
import { Button, Spin } from 'antd'
import { Bfx } from 'assets/icon'
import rocket from 'assets/background.jpg'
import { FC, ReactNode, useEffect, useState } from 'react'
import styled from 'styled-components'

const Box = styled.div<{ opacity: 0 | 1; isCenter: boolean }>`
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: #fff;
	z-index: 9999;
	display: flex;
	justify-content: center;
	padding: ${({ isCenter }) => (isCenter ? '0' : '20% 0 0 0')};
	opacity: ${({ opacity }) => opacity};
	transition: all 0.3s ease;

	svg {
		font-size: 15em;
		margin-right: 20px;
	}
`
const Content = styled.div`
	display: flex;
	justify-content: center;
	align-items: flex-start;
`

interface IProps {
	visible?: boolean | 'time'
	children?: ReactNode
}
export const CoverPage: FC<IProps> = ({ visible = 'time', children }) => {
	const [opacity, setOpacity] = useState<0 | 1>(0)
	const [show, setShow] = useState<boolean>(false)

	const toggle = (show) => {
		setOpacity(show ? 1 : 0)
		show ? setShow(true) : setTimeout(() => setShow(false), 250) // like transition
	}

	useEffect(() => {
		if (visible === true) {
			toggle(true)
		} else if (visible === false) {
			toggle(false)
		} else if (visible === 'time') {
			toggle(true)
			setTimeout(() => toggle(false), 800)
		}
	}, [visible])

	return show ? (
		<Box opacity={opacity} isCenter={!!children}>
			<Content>
				{children ? (
					children
				) : (
					<>
						<Bfx />
						<Spin spinning />
					</>
				)}
			</Content>
		</Box>
	) : null
}

const Box2 = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: #fff url(${rocket}) no-repeat center top;
	background-size: cover;
	z-index: 9999;
	padding: 0;
	opacity: 1;
	transition: all 0.3s ease;
	display: flex;
	justify-content: center;
	align-items: center;
`
const Content2 = styled.div`
	background: rgba(255, 255, 255, 0.9);
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding: 4% 8%;
	margin: 0 0 100px 0;
	text-align: center;

`
const TextBox = styled.div`
	font-size: 20px;
	font-weight: bold;
	

`
const Logo = styled.div`
	svg {
		font-size: 15em;
		margin-right: 20px;
	}
`
const LINK = 'https://exchange.bitclear.com/'

export const CoverPageNevApp = () => (
	<Box2>
		<Content2>
			<Logo><Bfx /></Logo>
			<br />
			<br />
			<TextBox>
				Welcome to our new design!
				<br />
				Visit <a href={LINK}>exchange.bitclear.com</a> to experience our fresh new look and features
			</TextBox>
			<br/>
			<br/>
			<br/>
			<Button style={{ width: '400px', height: '50px' }} size={'large'} type={'primary'} onClick={()=>location.href=LINK}>Start using the new website <RocketOutlined style={{ rotate: '45deg', fontSize: '1.2em' }}/></Button>
		</Content2>
	</Box2>
)
